import * as React from "react"
import '../css/bootstrap.css';
import {ThemeProvider} from "react-bootstrap";

import "../css/bootstrap.css";
import "../fonts/font-awesome/css/font-awesome.min.css";
import "../css/template.css";
import "../css/responsive.css";
import "../css/base-sizing.css";
import "../css/custom.css";

import HeaderAndMenu from "../page_components/common/HeaderAndMenu";
import Footer from "../page_components/common/Footer";
import BsHelmet from "../page_components/common/BsHelmet";
import CommonHero from "../page_components/common/CommonHero";
import {StaticImage} from "gatsby-plugin-image";


const IndexPage = ({data}) => {
    return (
        <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}>
            <BsHelmet title={"Futures Spread Trading"}/>
            <div id="page_wrapper">
                <HeaderAndMenu/>
                <CommonHero title={"Futures Spread Trading"}/>
                <section className="hg_section pt-70">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-10 text-justify">

                                <div className="row">
                                    <div className={"col-md-6"}>
                                        <h1 className="m_title">
                                            <span className="fw-thin fs-m text-justify">
                                                Introduction to <span
                                                className="fw-semibold">futures spread trading</span>
                                            </span>
                                        </h1>
                                        <p>
                                            <b>Crypto spread trading on futures</b> is trading strategy where the traders profits from the change
                                            in the price differences - <strong>the spread</strong> price - between two positions
                                            - short and long - on the two futures contracts that have the same underlying asset with different
                                            delivery dates, or on the two futures contracts with different underlying assets that are price
                                            correlated to each other with the same delivery date.
                                        </p>
                                    </div>
                                    <div className={"col-md-6  pt-10 pb-10 mb-20"}>
                                        <div className={"border p-3"}>
                                            <StaticImage src={"../gatsbyimg/medium.png"}
                                                         placeholder={"none"}
                                                         alt={"introduction to cryptocurrency futures spread trading "}
                                                         width={200}/>
                                            <h5>You can find <b>full introduction to cryptocurrency futures spread trading</b> in our Medium article here: <a
                                                href="https://medium.com/coinmonks/futures-spread-trading-e3aeabda52ff" className={'read-more-link'}>Futures Spread Trading</a></h5>
                                        </div>
                                    </div>
                                </div>

                                <p>Spread is the <strong>price difference between two futures contracts</strong>.

                                    The price of the each futures contract is formed by the market based on the market
                                    participants' expectations/calculations. As each futures contract is traded on separate market with different liquidity, the price volatility
                                    of each contract differs, resulting in changes in the spread price.
                                </p>
                                <p className={"text-center"}>
                                    <StaticImage src={"../gatsbyimg/screenshots/spreadPriceX.png"}
                                                 alt={"chart with spread price"}
                                                 style={{width: "80%", "textAlign": "bottom"}}/>
                                </p>

                                <p className={'fs-8 text-center'}>An example of the spread price changing through the time</p>

                                <p className={"text-justify"}>
                                    In order to profit we buy the spread, when the spread price is low and we sell
                                    the spread when its price is high. Or if we think that current spread price is high
                                    and we think it is going to fall, we sell the spread and we buy it back when the
                                    price has fallen.
                                </p>
                                <p>
                                    <b>Intramarket spreads</b>, also referred to as <b>calendar spreads</b>, involve
                                    buying a futures contract with delivery date in one month while simultaneously
                                    selling the contract on the same underlying asset with the delivery
                                    date in a different month. In most cases, there will be a loss in one leg
                                    of the spread, but a profit in the other leg. If the calendar spread is
                                    successful, the gain in the profitable leg will outweigh the loss in the
                                    losing leg.
                                    <br/>
                                </p>


                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>
            </div>
        </ThemeProvider>
    )
}

export default IndexPage
